import React, {useEffect, useState} from 'react'
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import PrimaryMenu from "../components/PrimaryMenu";
import SubPageNav from "../components/SubPageNav";
import IconList from "../components/IconList";
import ImageLinkBlock from '../components/ImageLinkBlock';

const ProgramTypeLanding = ({pageContext: pagePassedContext}) => {
    
    console.log(pageContext)
    const [pageContext, setContext] = useState(pagePassedContext);

    // get the data vars we need from the json data
    // this came from http://api.vanarts.com/api/pages
    //let data = pageContext.content.json_data.data;
    let navigation = pageContext.flyoutnav;
    let data = pageContext.content.json_data.data;
    let header = data.header;
    let nav = pageContext.flyoutnav;
    let linkList = data.content.subLinks;
    let actions = data.actions;

    // THIS GETS THE SUB NAVIGATION
    const subnavName = data.navigation;

    /**** MOVE TO A FUNCTION FILE  */
    function search(nameKey, myArray){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return myArray[i];
            }
        }
    }

    let subnav = search(subnavName, navigation).json_data.data.content.subLinks

    useEffect(function(){
        fetch("http://api.vanarts.com:3000/pages/"+pageContext.content.id).then(response=>{
            return response.json();
        }).then(function(json){
            setContext(json)
        })
    }, [])
    
    return (
        <Layout nav={nav}>
            <Hero image={header.cover}>
                <PrimaryMenu nav={nav}/>
            </Hero>
            
            <SubPageNav title={header.title} linksData={subnav}>
                <div dangerouslySetInnerHTML={{__html: header.headerintro}}></div>
            </SubPageNav>

            <article className="standard-content">
                <div className="pad">
                    <div dangerouslySetInnerHTML={{__html: data.content.bodyintro}}/>
                </div>
                <IconList links={linkList}/>
            </article>


            {
                actions.title && 
                <article className="standard-content">
                    <div className="pad">
                        <h2>{actions.title}</h2>
                        {
                            actions.boxes.map((action, i)=>(
                             <ImageLinkBlock img={action.image} to={action.url} label={action.name} style="vertical" />  
                            ))
                        }
                    </div>
                </article>
            }            

            
            {/* <article className="standard-content">
                <div className="pad">
                    <div dangerouslySetInnerHTML={{__html: data.introContent.introduction}}/>
                    <Link className="btn-outline-cta" to={data.introContent.actionBoxLink}>{data.introContent.actionBoxText}</Link>
                </div>
            </article> */}
            
            {/*             
            <CTABox label="Visit Our FAQ Page" url="/">
                <h3>Do You Still Have Questions? Visit Our FAQ Page Or Contact Us</h3>
            </CTABox>

            <CTABox label="Apply Now" url="/">
                <h3>Ready To Apply?</h3>
                <h4>Full Time Programs</h4>

                <p>There is a non-refundable $100 application fee required too submit your application. After applying, an advisor will contact you regarding the additional materials required to complete your application.</p>

                <h4>Part Time &amp; Summer Courses</h4>

                <p>The full registration fee is required to submit your application.</p>
            </CTABox> */}
            

        </Layout>
    )
}

export default ProgramTypeLanding;