import React from "react";
import {Link} from "gatsby"

import "./ImageLinkBlock.css";
import "../../css/global.scss";

export default function ImageLinkBlock({img, to, label, intro, style}){
    style = style || "";

    return (
        <div className={`image-block ${style}`}>
            <div className="image" style={{backgroundImage: `url("http://api.vanarts.com:3000/assets/${img}")`}}></div>
            <div className="detail">
                <h3><Link to={to}>{label}</Link></h3>
                {
                    intro && 
                    <p>{intro}</p>
                    
                }
            </div>
        </div>
    )
}